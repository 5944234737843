export default {
  id: {
    type: Number,
    visibility: false,
  },
  name: {
    label: "Nombre",
    type: String,
  },
  createdAt: {
    label: "Registrado",
    type: Date,
    visibility: false,
    optional: true,
  },
  lastUpdate: {
    label: "Ultima Actualizacion",
    type: Date,
    visibility: false,
    optional: true,
  },
  version: {
    label: "Version",
    type: Number,
    visibility: false,
    optional: true,
  },
};
